export const projects = [
  {
    id: 1,
    img: 'assets/images/timpGrotto.jpg',
    alt: 'Timpanogos Grotto',
    title: 'Timpanogos Grotto',
    lang: 'React, JavaScript, Redux, Reactstrap',
    desc: 'Website for the Timpanogos Grotto of the NSS',
    link: 'https://github.com/JWeyland12/ReactTimpGrottoSite',
    par: 'A website solution for the Timpanogos Grotto of the National Speleological Society. This project aims to deliver a valuable product to an organization in need of an online presence in order to communicate within the organization and represent themselves to the public.',
  },
  {
    id: 3,
    img: 'assets/images/logo.png',
    alt: 'NuCamp Website',
    title: 'NuCamp',
    lang: 'React, Redux, JavaScript, Reactstrap',
    desc: 'Mock campsite hosting company',
    link: 'https://github.com/JWeyland12/ReactNucampSite',
    par: 'Created in the NuCamp coding bootcamp, this website represents a mock campsite hosting company. Features include a directory of campsites, a live updated comment field utilizing redux, an informational "About Us" section, login modal, a contact form, and more.',
  },
  {
    id: 4,
    img: 'assets/images/RNhome.png',
    alt: 'NuCamp Website',
    title: 'NuCamp',
    lang: 'React Native',
    desc: 'Mock campsite hosting company',
    link: 'https://github.com/JWeyland12/ReactNativeNucampSite',
    par: 'Created in the NuCamp coding bootcamp, this website represents a mock campsite hosting company. Features include a directory of campsites, a live updated comment field utilizing redux, an informational "About Us" section, login modal, a contact form, and more.',
  },
  {
    id: 2,
    img: 'assets/images/timpGrotto.jpg',
    alt: 'Timpanogos Grotto',
    title: 'Timpanogos Grotto',
    lang: 'HTML5, Bootstrap, CSS',
    desc: 'Website for the Timpanogos Grotto of the NSS',
    link: 'https://github.com/JWeyland12/Timpanogos-Bootstrap-Site',
    par: 'A website solution for the Timpanogos Grotto of the National Speleological Society. This project aims to deliver a valuable product to an organization in need of an online presence in order to communicate within the organization and represent themselves to the public.',
  },
  {
    id: 5,
    img: 'assets/images/bioIt (1).png',
    alt: 'BioIt Inventory App',
    title: 'BioIt Inventory App',
    lang: 'JavaScript, React Native, Redux, Node, Express, MongoDB, Mongoose, AWS',
    link: 'https://github.com/JWeyland12/bioInventoryApp',
    par: 'Offline first bio inventory app to streamline the work of environmental professionals conducting bio inventories on karst properties.'
  }
]
export const carousel = [
  {
    index: 1, 
    id: 1,
    img: '/assets/images/scHome.png',
    alt: 'Home Page',
  },
  {
    index: 1, 
    id: 2,
    img: '/assets/images/scAboutUs.png',
    alt: 'About Page',
  },
  {
    index: 1, 
    id: 3,
    img: '/assets/images/scMeet.png',
    alt: 'Officer Page',
  },
  {
    index: 1, 
    id: 4,
    img: '/assets/images/scFAQ.png',
    alt: 'FAQ Page',
  },
  {
    index: 1, 
    id: 5,
    img: '/assets/images/scJoin.png',
    alt: 'Join Page',
  },
  {
    index: 1, 
    id: 6,
    img: '/assets/images/scEvents.png',
    alt: 'Events Page',
  },
  {
    index: 1, 
    id: 7,
    img: '/assets/images/scContact.png',
    alt: 'Contact Page',
  },
  {
    index: 2, 
    id: 5,
    img: '/assets/images/scHome.png',
    alt: 'Home',
  },
  {
    index: 2, 
    id: 6,
    img: '/assets/images/scContact.png',
    alt: 'Contact Page',
  },
  {
    index: 2, 
    id: 7,
    img: '/assets/images/scMeet.png',
    alt: 'Officers page',
  },
  {
    index: 2, 
    id: 8,
    img: '/assets/images/scAboutUs.png',
    alt: 'About Us',
  },
  {
    index: 3, 
    id: 9,
    img: '/assets/images/scHomePage.png',
    alt: 'NuCamp Home',
  },
  {
    index: 3, 
    id: 10,
    img: '/assets/images/scFullDirectory.png',
    alt: 'Directory page',
  },
  {
    index: 3, 
    id: 11,
    img: '/assets/images/scDirectory.png',
    alt: 'Directory View',
  },
  {
    index: 3, 
    id: 12,
    img: '/assets/images/scComment.png',
    alt: 'Comment Form',
  },
  {
    index: 3, 
    id: 13,
    img: '/assets/images/scAbout.png',
    alt: 'About Page',
  },
  {
    index: 3, 
    id: 14,
    img: '/assets/images/scContactUs.png',
    alt: 'Contact Page',
  },
  {
    index: 4, 
    id: 15,
    img: '/assets/images/RNhome.png',
    alt: 'home',
  },
  {
    index: 4, 
    id: 16,
    img: '/assets/images/RNlogin.png',
    alt: 'login',
  },
  {
    index: 4, 
    id: 17,
    img: '/assets/images/RNregister.png',
    alt: 'register',
  },
  {
    index: 4, 
    id: 18,
    img: '/assets/images/RNdirectory.png',
    alt: 'directory',
  },
  {
    index: 4, 
    id: 19,
    img: '/assets/images/RNcampsiteInfo.png',
    alt: 'campsite info',
  },
  {
    index: 4, 
    id: 20,
    img: '/assets/images/RNreview.png',
    alt: 'review page',
  },
  {
    index: 4, 
    id: 21,
    img: '/assets/images/RNshare.png',
    alt: 'share page',
  },
  {
    index: 4, 
    id: 22,
    img: '/assets/images/RNfavorite.png',
    alt: 'favorites',
  },
  {
    index: 4, 
    id: 23,
    img: '/assets/images/RNdeleteFavorite.png',
    alt: 'delete favorite',
  },
  {
    index: 4, 
    id: 24,
    img: '/assets/images/RNaboutUs.png',
    alt: 'about',
  },
  {
    index: 4, 
    id: 25,
    img: '/assets/images/RNcontactUs.png',
    alt: 'contact',
  },
  {
    index: 4, 
    id: 26,
    img: '/assets/images/RNreservation.png',
    alt: 'reservation',
  },
  {
    index: 5, 
    id: 27,
    img: '/assets/images/bioIt (1).png',
    alt: 'Home Page',
  },
  {
    index: 5, 
    id: 28,
    img: '/assets/images/bioIt (2).png',
    alt: 'Create Area Page',
  },
  {
    index: 5, 
    id: 29,
    img: '/assets/images/bioIt (3).png',
    alt: 'Trips Page',
  },
]